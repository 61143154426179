import React, {useRef} from "react";
import * as styles from "./devis-car-location.module.scss";
import Layout from "../../components/layout/layout";
import {graphql} from "gatsby";
import {DevisLocationCarQuery} from "../../../graphql-types";
import PageCover from "../../components/common/page-cover/page-cover";
import LinksGroupSide from "../../components/common/links-group-side/links-group-side";
import useWindowWidth from "../../hooks/use-window-width";

type ReactProps = {
    data: DevisLocationCarQuery
}

const DevisLocationCarPage:React.FC<ReactProps> = ({data}) => {
    const isMobile = useWindowWidth() <= 400;
    const isTablet = useWindowWidth() > 400 && useWindowWidth() < 1200

    // change flutter source from devis location in preprod (qualif)
    const flutterEmbed = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ?
        "https://pksakoccazewstatwebv2.z6.web.core.windows.net/" :
        "https://pksakoccazewstatwebv2.z6.web.core.windows.net/";
    const iframe = useRef(null);

    return (
        <Layout data={data.datoCmsFooter} stickyButtonsData={data.datoCmsWebStickyButton} chatbotData={data.datoCmsChatbot} seo={data.datoCmsWebDevisGratuit.seo} pageTemplate="demande de devis">
            <PageCover
                title={data.datoCmsWebDevisGratuit.cover[0] && data.datoCmsWebDevisGratuit.cover[0].title}
                image={isTablet ? data.datoCmsWebDevisGratuit.cover[0] && data.datoCmsWebDevisGratuit.cover[0].backgroundImageDevis : isMobile ? data.datoCmsWebDevisGratuit.cover[0] && data.datoCmsWebDevisGratuit.cover[0].mobileImage : data.datoCmsWebDevisGratuit.cover[0] && data.datoCmsWebDevisGratuit.cover[0].backgroundImageDevis}
            />
            <section className={styles.pageContainer}>
                <div className={styles.pageContent}>
                    {!isMobile && <LinksGroupSide data={data.datoCmsWebDevisGratuit.linksGroup} />}
                    <div className={styles.content}>
                        <iframe
                            ref={iframe}
                            id="devisIframe"
                            className={styles.iframe}
                            scrolling="yes"
                            src={flutterEmbed}
                            frameBorder="0"
                            height="600"
                            allowFullScreen
                            sandbox="allow-same-origin allow-scripts allow-downloads allow-popups allow-forms allow-presentation"
                        />
                    </div>
                    {isMobile && <LinksGroupSide data={data.datoCmsWebDevisGratuit.linksGroup} />}
                </div>
            </section>
        </Layout>
    )

}

export const fragment = graphql`
    fragment DevisGratuitWebFields on DatoCmsWebDevisGratuit {
        seo {
          description
          title
        }
        cover {
            title
            backgroundImageDevis {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            mobileImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        linksGroup {
            id
            title
            titleAttribute
            slug
            mainColor {
                hex
            }       
            icon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            iconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIcon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
    }
`

export const pageQuery = graphql`
    query DevisLocationCar($locale: String!) {  
        datoCmsFooter(locale: {  eq: $locale }) {
            ...FooterFields
        }   
        datoCmsWebStickyButton(locale: {  eq: $locale }) {
            ...StickyButtonsFields
        }
        datoCmsChatbot(locale: { eq: $locale }) {
            ...ChatbotFields
        }
        datoCmsWebDevisGratuit(locale: {  eq: $locale }) {
            ...DevisGratuitWebFields
        }
    }
`

export default DevisLocationCarPage;